import React from "react"
import { Link } from "gatsby"

const RelapsedRefractorySubmenu = () => {
  return (
    <>
      <li><Link data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="What is Relapsed/Refractory Neuroblastoma?" activeClassName="isActive" className="isActive" to="/relapsed-refractory-neuroblastoma/" partiallyActive={true}>What is Relapsed/Refractory Neuroblastoma? <span className="np-arrow">&rsaquo;</span></Link></li>
    </>
  )
}

export default RelapsedRefractorySubmenu
